import React, {FC} from 'react';
import {Route, Switch} from 'react-router-dom';

import {NotFoundPage} from '../../notFound';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';

import {DocumentsTab} from './documentsTab';
import {ElectronicRecordTab} from './electronicRecordTab';
import {MedicalDocumentsTab} from './medicalDocumentsTab';
import {ViewOrdersTab} from './viewOrdersTab';

type Props = {
  consumerUuid: string;
  primaryInsuranceId: number | null;
  secondaryInsuranceId: number | null;
  testId: string;
  patientFirstName: string;
  patientLastName: string;
};

const TABS = {
  medicalDocuments:
    PORTAL_PATIENTS_ROUTES.PATIENT_TAB.concat('/medical-documents'),
  electronicRecord: PORTAL_PATIENTS_ROUTES.PATIENT_TAB.concat(
    '/electronic-record/:section'
  ),
  idAndInsuranceCards: PORTAL_PATIENTS_ROUTES.PATIENT_TAB.concat(
    '/id-and-insurance-cards'
  ),
  viewOrders: PORTAL_PATIENTS_ROUTES.PATIENT_TAB.concat('/view-orders'),
  notFound: PORTAL_PATIENTS_ROUTES.PATIENT_TAB.concat('/*'),
};

export const TabContentSwitcher: FC<Props> = ({
  testId,
  primaryInsuranceId,
  secondaryInsuranceId,
  consumerUuid,
  patientFirstName,
  patientLastName,
}) => (
  <Switch>
    <Route exact path={TABS.medicalDocuments}>
      <MedicalDocumentsTab testId={testId} />
    </Route>
    <Route exact path={TABS.idAndInsuranceCards}>
      <DocumentsTab
        testId={testId}
        consumerUuid={consumerUuid}
        primaryInsuranceId={primaryInsuranceId}
        secondaryInsuranceId={secondaryInsuranceId}
        patientFirstName={patientFirstName}
        patientLastName={patientLastName}
      />
    </Route>
    <Route exact path={TABS.electronicRecord}>
      <ElectronicRecordTab testId={testId} consumerUuid={consumerUuid} />
    </Route>
    <Route exact path={TABS.viewOrders}>
      <ViewOrdersTab testId={testId} consumerUuid={consumerUuid} />
    </Route>
    <Route path={TABS.notFound}>
      <NotFoundPage />
    </Route>
  </Switch>
);
