import React, {ReactNode} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: ({hasPadding}: {hasPadding: boolean}) =>
      hasPadding ? '30px' : '0',
    boxSizing: 'border-box',

    '&:focus': {
      outline: 'none',
    },
  },
}));

type Props = {
  open: boolean;
  children: ReactNode;
  container?: HTMLElement | null;
  onClose?: VoidFunction;
  minWidth?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  maxWidth?: number | string;
  padding?: number | string;
  hasPadding?: boolean;
};

export const BaseModalWindow = ({
  open,
  container,
  onClose,
  children,
  minWidth = 626,
  maxWidth = 826,
  minHeight,
  maxHeight,
  padding,
  hasPadding = true,
}: Props): JSX.Element => {
  const classes = useStyles({hasPadding});

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={open}
      onClose={onClose}
      container={container ?? document.body}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          className={classes.paper}
          style={{
            maxWidth,
            minWidth,
            minHeight,
            maxHeight,
            padding,
          }}
        >
          {children}
        </div>
      </Fade>
    </Modal>
  );
};
