import {useState} from 'react';

import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {wrapAsyncFunction} from '../../../shared/utils/wrapAsyncFunction';
import {CallBackFuncType} from '../constants/types';

type HookReturnType = {
  resolutionAction: CallBackFuncType;
  isResolutionLoading: boolean;
};

export const useResolutionAction = (): HookReturnType => {
  const showMessage = useCustomizedSnackbar();
  const [isResolutionLoading, setIsResolutionLoading] = useState(false);

  const resolutionAction = async (callback: () => Promise<void>) => {
    setIsResolutionLoading(true);

    try {
      await callback();
    } catch (error) {
      showMessage(
        'Something went wrong. Please try to refresh the page.',
        'error'
      );
    } finally {
      setIsResolutionLoading(false);
    }
  };

  return {
    resolutionAction: wrapAsyncFunction(resolutionAction),
    isResolutionLoading,
  };
};
