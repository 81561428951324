import React, {FC, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {Button} from '../../../shared/components/button';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {PORTAL_PATIENTS_ROUTES} from '../../portalPatients/constants/routes';
import {CallBackFuncType, ResolutionEnum} from '../constants/types';
import {useCustomizedButtonStyles} from '../hooks/useCustomizedButtonStyles';
import {fetchInboxProviderResolutionEvent} from '../utils/fetchers';

type Props = {
  handleInviteActionCallBack: CallBackFuncType;
  eventId: string;
  invitationId: number;
};

export const InvitePatientButton: FC<Props> = ({
  handleInviteActionCallBack,
  eventId,
  invitationId,
}) => {
  const classes = useCustomizedButtonStyles();
  const history = useHistory();
  const snackbar = useCustomizedSnackbar();

  const handleInviteAction = useCallback(async () => {
    try {
      await fetchInboxProviderResolutionEvent(
        ResolutionEnum.COMPLETED,
        eventId
      );
      history.push(
        PORTAL_PATIENTS_ROUTES.INVITATION_DETAILS.replace(
          ':invitationId',
          invitationId.toString()
        )
      );
    } catch {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
  }, [eventId, history, invitationId, snackbar]);

  const onClick = useCallback(
    () => handleInviteActionCallBack(handleInviteAction),
    [handleInviteAction, handleInviteActionCallBack]
  );

  return (
    <Button
      position="section"
      onClick={onClick}
      data-testid={`inbox-invite-btn-${eventId}`}
      variant="outlined"
      className={classes.customizedBtn}
    >
      Invite
    </Button>
  );
};
