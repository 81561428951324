import {ProviderDirectoryLocation} from '../constants/types';

export const getProviderDirectoryAddress = (
  addressDetails: ProviderDirectoryLocation
): {firstLine?: string; secondLine?: string} => ({
  firstLine: addressDetails.address_1,
  secondLine:
    addressDetails.city && addressDetails.city && addressDetails.state
      ? `${addressDetails.postal_code}, ${addressDetails.state} ${addressDetails.postal_code}`
      : undefined,
});
