import React, {FC, useCallback} from 'react';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  MenuProps,
  Select,
} from '@material-ui/core';
import {useFormikContext} from 'formik';
import styled from 'styled-components';

import {
  InputFormControlWithFormik,
  InputHelperText,
  InputLabel,
} from '../../../shared/components/form';
import {ANCHOR, colors} from '../../../shared/styles/theme';
import {EHR} from '../../portalPatients/hooks/useEHRs';
import {RequestRecordsFormValuesTypes} from '../constants/types';

export const EhrAndMrnForm: FC<{
  ehrFiledName: 'haveMedicalRecordsEhrId' | 'doNotHaveMedicalRecordsEhrId';
  mrnFiledName: 'haveMedicalRecordsMrn' | 'doNotHaveMedicalRecordsMrn';
  EHRs: EHR[];
  EHRsIsLoading: boolean;
  EHRsHasError: null | string;
}> = ({ehrFiledName, mrnFiledName, EHRs, EHRsIsLoading, EHRsHasError}) => {
  const formik = useFormikContext<RequestRecordsFormValuesTypes>();
  const getEHRname = useCallback(
    (selectedEHRid: string) => {
      const ehr = EHRs.find(({id}) => id === selectedEHRid);
      return ehr?.name ?? <Placeholder>Select EHR</Placeholder>;
    },
    [EHRs]
  );

  const getEHRmenuItems = () => {
    if (!EHRsIsLoading && !EHRsHasError && EHRs.length !== 0) {
      return EHRs.map(ehr => (
        <MenuItem key={ehr.id} value={String(ehr.id)}>
          {ehr.name}
        </MenuItem>
      ));
    }

    return null;
  };

  const isMrnRequired = formik.values.isRequestHaveMedicalRecords === 'have';

  const renderValue = useCallback(
    () => getEHRname(formik.values[ehrFiledName]),
    [ehrFiledName, formik.values, getEHRname]
  );
  const onBlur = useCallback(
    e => {
      formik.handleBlur(e);
      formik.setFieldTouched('ehr', true);
    },
    [formik]
  );

  return (
    <>
      <span>
        Add MRN&nbsp;
        {!isMrnRequired ? <i>(optional)</i> : null}
      </span>
      <FormWrapper>
        <div>
          <SelectWrapper
            variant="outlined"
            required={isMrnRequired}
            error={
              !!formik.errors[ehrFiledName] && !!formik.touched[ehrFiledName]
            }
          >
            <InputLabel id="ehrId-label">EHR</InputLabel>
            <Select
              labelId="ehrId-label"
              id="ehrId"
              name={ehrFiledName}
              data-testid="ehr-select"
              inputProps={{
                'data-testid': 'ehr-select-input',
              }}
              MenuProps={ANCHOR as Partial<MenuProps>}
              renderValue={renderValue}
              value={String(formik.values[ehrFiledName])}
              onChange={formik.handleChange}
              onBlur={onBlur}
            >
              <MenuItem
                key="default"
                value="default"
                style={{
                  width: '100%',
                  pointerEvents: 'none',
                  whiteSpace: 'normal',
                  backgroundColor: 'transparent',
                  maxWidth: EHRsIsLoading ? 'inherit' : 'fit-content',
                  display:
                    EHRsIsLoading || EHRsHasError || EHRs.length === 0
                      ? 'inline-block'
                      : 'none',
                }}
              >
                {EHRsIsLoading ? (
                  <LoadingWrapper>
                    <div>Loading...</div>
                    <CircularProgress color="inherit" size={16} />
                  </LoadingWrapper>
                ) : null}

                {EHRsHasError && !EHRsIsLoading ? (
                  <div style={{color: colors.red}}>{EHRsHasError}</div>
                ) : null}

                {EHRs.length === 0 && !EHRsIsLoading && !EHRsHasError ? (
                  <div style={{color: colors.grey801}}>
                    No data is available
                  </div>
                ) : null}
              </MenuItem>

              {getEHRmenuItems()}
            </Select>
            <InputHelperText shrink>
              {(formik.touched[ehrFiledName] && formik.errors[ehrFiledName]) ||
                ''}
            </InputHelperText>
          </SelectWrapper>
        </div>
        <InputFormControlWithFormik
          required={isMrnRequired}
          id="mrn"
          width="auto"
          name={mrnFiledName}
          label="MRN"
          placeholder="Type MRN"
          testIdPrefix="mrn"
          error={
            (formik.touched[mrnFiledName] && formik.errors[mrnFiledName]) || ''
          }
        />
      </FormWrapper>
    </>
  );
};

const SelectWrapper = styled(FormControl)`
  width: 100%;
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 222px 222px;
  grid-gap: 20px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Placeholder = styled.span`
  opacity: 0.42;
`;
