import React, {FC, useMemo} from 'react';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import styled from 'styled-components';

import {LoadingBar} from '../../../shared/components/loadingBar';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {SmthWentWrongPlaceholder} from '../../../shared/components/smthWentWrongPlaceholder';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {HttpResponseError} from '../../../shared/utils/httpResponseError';
import {MedicalRecordsRequestType} from '../constants/types';
import {getViewOrdersTableCells} from '../constants/viewOrdersTableConfig';
import {useMedicalRecrodsOrders} from '../hooks/useMedicalRecordsOrders';

import {PermissionDenied} from './permissionDenied';

type Props = {
  testId: string;
  consumerUuid: string;
};

export const ViewOrdersTab: FC<Props> = ({testId, consumerUuid}) => {
  const columns = getViewOrdersTableCells();
  const {
    data: recordOrders,
    isLoading,
    isError,
    refetch,
    error,
  } = useMedicalRecrodsOrders(consumerUuid);

  const table = useReactTable<MedicalRecordsRequestType>({
    data: recordOrders,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const tablePlaceholder = useMemo(() => {
    if (recordOrders.length === 0 && !isError) {
      return (
        <NoDataPlaceholderWrapper>
          <NoDataPlaceholder noDataText="There are no Records Requests yet" />
        </NoDataPlaceholderWrapper>
      );
    }
    return null;
  }, [isError, recordOrders.length]);

  const renderError = () =>
    error instanceof HttpResponseError && error.response.status === 403 ? (
      <CenteringErrorPlaceholder>
        <PermissionDenied />
      </CenteringErrorPlaceholder>
    ) : (
      <CenteringErrorPlaceholder>
        <SmthWentWrongPlaceholder
          labelText="Something went wrong during request processing. Please retry."
          onClick={refetch}
        />
      </CenteringErrorPlaceholder>
    );

  return (
    <TabWrapper data-testid={testId}>
      <LoadingBar loading={isLoading} />
      {isError && renderError()}

      {!isLoading && !isError ? (
        <TableWrapper>
          <StyledTable
            table={table}
            testId="medical-documents"
            placeholderComponent={tablePlaceholder}
          />
        </TableWrapper>
      ) : null}
    </TabWrapper>
  );
};

const TabWrapper = styled.div`
  position: relative;
`;

const TableWrapper = styled.div`
  margin: 20px 0 24px 0;
`;

const NoDataPlaceholderWrapper = styled.div`
  margin-bottom: 80px;
`;

const CenteringErrorPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding: 100px 0;
`;
