import {useCallback, useEffect, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {PatientsNamesType, RemappedPatientsNamesType} from '../constants/types';
import {fetchPatientsNames} from '../utils/fetchers';

type HookReturnType = {
  patientsIsLoading: boolean;
  patientsNames: RemappedPatientsNamesType[];
  patientsNamesError: boolean;
};

export const usePatientsNames = (): HookReturnType => {
  const [patientsIsLoading, setPatientsIsLoading] = useState<boolean>(false);
  const [patientsNames, setPatientsNames] = useState<PatientsNamesType[]>([]);
  const [patientsNamesError, setPatientsNamesError] = useState<boolean>(false);
  const currentUser = useRecoilValue(currentUserState);

  const getPatientsNames = useCallback(async relyingPartyId => {
    setPatientsIsLoading(true);
    try {
      const patientsList: PatientsNamesType[] =
        await fetchPatientsNames(relyingPartyId);
      setPatientsNames(patientsList);
    } catch {
      setPatientsNamesError(true);
    } finally {
      setPatientsIsLoading(false);
    }
  }, []);

  const remappedPatientsNames: RemappedPatientsNamesType[] = useMemo(() => {
    if (patientsNames.length > 0) {
      return patientsNames.map((patient: PatientsNamesType) => ({
        label: `${patient.first_name} ${patient.last_name}`,
      }));
    }
    return [];
  }, [patientsNames]);

  useEffect(() => {
    getPatientsNames(currentUser?.relyingParty.id);
  }, [currentUser?.relyingParty.id, getPatientsNames]);

  return {
    patientsIsLoading,
    patientsNames: remappedPatientsNames,
    patientsNamesError,
  };
};
