import React, {useCallback, useMemo, useState} from 'react';
import {Redirect, useLocation, useParams} from 'react-router-dom';
import {Box, Typography} from '@material-ui/core';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button} from '../../../shared/components/button';
import {ConfirmModal} from '../../../shared/components/confirmModal';
import {Description} from '../../../shared/components/description';
import {DropZone} from '../../../shared/components/dropZone';
import {ExitBar} from '../../../shared/components/exitBar';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {RedirectToFirstAvailableRoute} from '../../../shared/components/redirectToFirstAvailableRoute';
import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useMoveToPreviousLocation} from '../../../shared/hooks/useMoveToPreviousLocation';
import {User} from '../../../shared/interfaces/user';
import {colors} from '../../../shared/styles/theme';
import {isRoleMatched} from '../../../shared/utils/user';
import {EVENTS_CATEGORY, ResolutionEnum} from '../../inbox/constants/types';
import {useRequestedRecordInfo} from '../../inbox/hooks/useGetRequestedRecordInfo';
import {fetchInboxProviderResolutionEvent} from '../../inbox/utils/fetchers';
import {
  outstandingRecordRequestsModalWindowDefaultState,
  outstandingRecordRequestsModalWindowState,
} from '../atoms/outstandingRecordRequestsModalWindowState';
import {CONNECTION_STATUSES} from '../constants/patientStatuses';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {useOutstandingRecords} from '../hooks/useOutstandingRecords';
import {usePatientLimitedDetails} from '../hooks/usePatientLimitedDetails';
import {usePatientsFiles} from '../hooks/usePatientsFiles';

import {OutstandingRecordRequestsModalWindow} from './outstandingRecordRequestsModalWindow';
import {UploadMedicalRecordsDataViewer} from './uploadMedicalRecordsDataViewer';
import {UploadRecordsHeader} from './uploadRecordsHeader';

const getTextForRequestedRecordsType = (
  requestedRecordType: EVENTS_CATEGORY | null
): string => {
  switch (requestedRecordType) {
    case EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPLOAD:
    case EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPDATE:
      return 'Medical Records';
    case EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPLOAD:
    case EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPDATE:
      return 'Images';
    default:
      return 'Records';
  }
};

const hasRightRolesForViewRecords = (
  currentUser: User | null,
  isPhysician: boolean,
  isPatientConnected: boolean,
  isSharingMedicalRecords: string | boolean
): string | boolean | undefined => {
  const allowedRolesPhysician = [
    ROLES.RELYING_PARTY_OWNER,
    ROLES.RELYING_PARTY_ADMIN,
    ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
  ];
  const allowedRolesNonPhysician = [
    ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
  ];
  const allowedRoles = isPhysician
    ? allowedRolesPhysician
    : allowedRolesNonPhysician;

  const typeUser = isPhysician
    ? USER_TYPES.PHYSICIAN
    : USER_TYPES.NON_PHYSICIAN;

  return (
    isRoleMatched(currentUser?.roles, allowedRoles) &&
    currentUser?.type === typeUser &&
    isPatientConnected &&
    isSharingMedicalRecords
  );
};

export const UploadMedicalRecordsData: React.FC = () => {
  const snackbar = useCustomizedSnackbar();
  const {getPreviousLocationPath} = useMoveToPreviousLocation();
  const setOutstandingRecordsModalWindow = useSetRecoilState(
    outstandingRecordRequestsModalWindowState
  );
  const [redirectLink, setRedirectLink] = useState<string | null>(null);
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] =
    useState<boolean>(false);
  const currentUser = useRecoilValue(currentUserState);
  const {
    onLoadFilesForPreview,
    onSelectFileForPreview,
    onRetryUpload,
    onDeleteFile,
    onSaveFiles,
    filesForPreview,
    filePreviewIsLoading,
    filePreviewHasError,
    filePreviewData,
    filesIsSaving,
    selectedFileForPreview,
  } = usePatientsFiles();
  const {isLoading: isHandlingOutstandingRecords, handleOutstandingRecords} =
    useOutstandingRecords();
  const {search} = useLocation();
  const {patientUuid} = useParams<{patientUuid: string}>();
  const {data: patientDetails, isLoading: isPatientDetailsLoading} =
    usePatientLimitedDetails(patientUuid, currentUser?.relyingParty.id ?? null);

  const eventId = useMemo(() => {
    const eventId = new URLSearchParams(search).get('event-id');

    return eventId ?? '';
  }, [search]);

  const {requestedRecordType, requestedRecordTypeIsLoading, eventDetails} =
    useRequestedRecordInfo(eventId);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      await onLoadFilesForPreview(acceptedFiles);
    },

    [onLoadFilesForPreview]
  );

  const textForRequestedRecordsType: string = useMemo(
    () => getTextForRequestedRecordsType(requestedRecordType),
    [requestedRecordType]
  );

  const isPatientConnected =
    patientDetails?.connection.status === CONNECTION_STATUSES.CONNECTED;

  const isSharingMedicalRecords =
    patientDetails?.connection.share_patient_to_provider ?? eventId;

  const isPhysicianHasRightRolesForViewRecords = useMemo(
    () =>
      hasRightRolesForViewRecords(
        currentUser,
        true,
        isPatientConnected,
        isSharingMedicalRecords
      ),
    [currentUser, isPatientConnected, isSharingMedicalRecords]
  );

  const isNonPhysicianHasRightRolesForViewRecords = useMemo(
    () =>
      hasRightRolesForViewRecords(
        currentUser,
        false,
        isPatientConnected,
        isSharingMedicalRecords
      ),
    [currentUser, isPatientConnected, isSharingMedicalRecords]
  );

  const isPatientDetailsReady =
    !isPatientDetailsLoading &&
    patientDetails &&
    isPatientConnected &&
    isSharingMedicalRecords;

  const isRequestedRecordTypeReady =
    requestedRecordType && !requestedRecordTypeIsLoading;

  const isAllowedUploadRecords = search
    ? isPatientDetailsReady && isRequestedRecordTypeReady
    : isPatientDetailsReady;

  const isRedirect = useMemo(
    () =>
      !!(!isPatientDetailsLoading && patientDetails && !isAllowedUploadRecords),
    [isAllowedUploadRecords, isPatientDetailsLoading, patientDetails]
  );

  const canSaveFiles = useMemo(
    () =>
      filesForPreview.length !== 0 &&
      filesForPreview.every(file => file.isUploaded),
    [filesForPreview]
  );

  const getDefaultCancelPath = useCallback(() => {
    if (eventId) {
      return PORTAL_PATIENTS_ROUTES.MEDICAL_RECORDS_REQUEST.replace(
        ':patientUuid',
        patientUuid
      ).concat(`?event-id=${eventId}`);
    }

    if (
      !eventId &&
      (isPhysicianHasRightRolesForViewRecords ||
        isNonPhysicianHasRightRolesForViewRecords)
    ) {
      return PORTAL_PATIENTS_ROUTES.CONNECTIONS_PATIENT_PROFILE.replace(
        ':patientUuid',
        patientUuid
      ).replace(':patientTab', 'medical-documents');
    }
  }, [
    eventId,
    isNonPhysicianHasRightRolesForViewRecords,
    isPhysicianHasRightRolesForViewRecords,
    patientUuid,
  ]);

  const getDefaultSavePath = useCallback(() => {
    if (eventId) {
      return '/inbox/to-do';
    }

    if (
      !eventId &&
      (isPhysicianHasRightRolesForViewRecords ||
        isNonPhysicianHasRightRolesForViewRecords)
    ) {
      return PORTAL_PATIENTS_ROUTES.CONNECTIONS_PATIENT_PROFILE.replace(
        ':patientUuid',
        patientUuid
      ).replace(':patientTab', 'medical-documents');
    }

    return getPreviousLocationPath();
  }, [
    eventId,
    getPreviousLocationPath,
    isNonPhysicianHasRightRolesForViewRecords,
    isPhysicianHasRightRolesForViewRecords,
    patientUuid,
  ]);

  const onResolveEvents = useCallback(
    async (eventIds: string[]) => {
      try {
        if (eventIds.length !== 0) {
          await Promise.all(
            eventIds.map(eventId =>
              fetchInboxProviderResolutionEvent(
                ResolutionEnum.COMPLETED_MEDICAL_RECORDS_UPLOADED,
                eventId
              )
            )
          );
          snackbar('Your Inbox has been updated', 'success');
        } else {
          snackbar('No changes were made to your Inbox', 'success');
        }
      } catch (e) {
        snackbar('A processing error occurred.', 'error');
      }
    },
    [snackbar]
  );

  const onResolveSingleEvent = useCallback(
    async (eventId?: string) => {
      if (eventId) {
        await onResolveEvents([eventId]);
      }

      setRedirectLink(getDefaultSavePath());
    },
    [getDefaultSavePath, onResolveEvents]
  );

  const onResolveMultipleOutstandingEvents = useCallback(
    async (eventIds: string[]) => {
      await onResolveEvents(eventIds);

      setOutstandingRecordsModalWindow(
        outstandingRecordRequestsModalWindowDefaultState
      );
    },
    [onResolveEvents, setOutstandingRecordsModalWindow]
  );

  const goToDefaultSavePath = useCallback(() => {
    setRedirectLink(getDefaultSavePath());
  }, [getDefaultSavePath]);

  const goBack = useCallback(() => {
    const redirectPath = getDefaultCancelPath();
    setRedirectLink(getPreviousLocationPath(redirectPath));
  }, [getDefaultCancelPath, getPreviousLocationPath]);

  const onSaveUploadedFiles = useCallback(async () => {
    await onSaveFiles();

    if (eventId) {
      await onResolveSingleEvent(eventId);
    } else if (currentUser?.relyingParty.id) {
      handleOutstandingRecords(
        currentUser.relyingParty.id,
        patientUuid,
        onResolveSingleEvent
      );
    }
  }, [
    currentUser?.relyingParty.id,
    eventId,
    handleOutstandingRecords,
    onResolveSingleEvent,
    onSaveFiles,
    patientUuid,
  ]);

  const onToggleConfirmCancelModalWindow = useCallback(() => {
    if (filesForPreview.length !== 0) {
      setIsConfirmCancelOpen(prevState => !prevState);
    } else {
      goBack();
    }
  }, [filesForPreview.length, goBack]);

  if (redirectLink) {
    return <Redirect to={redirectLink} />;
  }

  return (
    <>
      <Container>
        <div>
          <LoadingBar
            loading={
              (isPatientDetailsLoading && !patientDetails) ||
              filesIsSaving ||
              (!requestedRecordType && requestedRecordTypeIsLoading) ||
              isHandlingOutstandingRecords
            }
          />
        </div>

        {isRedirect ? <RedirectToFirstAvailableRoute /> : null}

        {isAllowedUploadRecords ? (
          <>
            <ExitBar
              onClick={onToggleConfirmCancelModalWindow}
              title={`Upload ${textForRequestedRecordsType}`}
              testID="exit-btn"
            />
            <Body>
              <Description
                title={`Upload ${textForRequestedRecordsType}`}
                description="On this page you can upload patient's HealthID medical records with new C-CDA documents, DICOM files, and other documents."
              />
              {patientDetails && (
                <UploadRecordsHeader
                  patientsProfileDetails={patientDetails}
                  eventDetails={eventDetails}
                />
              )}
              <Description description="C-CDA Viewing: This feature allows quick preview of standard C-CDA documents." />

              <Box flexGrow={1} display="flex" flexDirection="column">
                <DropZone
                  hasFiles={filesForPreview.length !== 0}
                  onDrop={onDrop}
                />

                {filesForPreview.length !== 0 ? (
                  <UploadMedicalRecordsDataViewer
                    filePreviewIsLoading={filePreviewIsLoading}
                    filePreviewHasError={filePreviewHasError}
                    filePreviewData={filePreviewData}
                    filesForPreview={filesForPreview}
                    selectedFileForPreview={selectedFileForPreview}
                    onSelectFileForPreview={onSelectFileForPreview}
                    onDeleteFile={onDeleteFile}
                    onRetryUpload={onRetryUpload}
                  />
                ) : null}
              </Box>
            </Body>
          </>
        ) : null}
      </Container>

      <ActionButtonsWrapper>
        <Button
          onClick={onToggleConfirmCancelModalWindow}
          variant="outlined"
          data-testid="cancel-btn"
        >
          Cancel
        </Button>

        <Button
          data-testid="upload-btn"
          disabled={!canSaveFiles}
          onClick={onSaveUploadedFiles}
        >
          Save
        </Button>
      </ActionButtonsWrapper>

      <ConfirmModal
        title="Unsaved changes"
        isOpen={isConfirmCancelOpen}
        variant="h6"
        onClose={onToggleConfirmCancelModalWindow}
        onConfirm={goBack}
        confirmBtnText="Leave without saving"
        confirmBtnVariant="primary"
      >
        <ConfirmModalContentWrapper>
          <Typography component="p">
            Are you sure you want to leave the page without saving your changes?
          </Typography>
        </ConfirmModalContentWrapper>
      </ConfirmModal>

      <OutstandingRecordRequestsModalWindow
        onResolveOutstandingEvents={onResolveMultipleOutstandingEvents}
        goToDefaultSavePath={goToDefaultSavePath}
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: auto;
  flex-direction: column;
  flex-grow: 1;
`;

const Body = styled.div`
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 60px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ActionButtonsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.grey50};
  padding: 20px 60px 20px 40px;
  box-sizing: border-box;
  margin-top: 8px;
  position: sticky;
  background-color: ${colors.white};
  bottom: 0;
`;

const ConfirmModalContentWrapper = styled.div`
  margin: 16px 0 40px;
`;
