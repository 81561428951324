import React, {FC, ReactNode} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {AddButton} from '../../../shared/components/button';
import {Drawer} from '../../../shared/components/drawer';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {Subheader} from '../../../shared/components/subheader';
import {Tab} from '../../../shared/components/tab';
import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {Crumb} from '../../../shared/interfaces/crumb';
import {User} from '../../../shared/interfaces/user';
import {colors} from '../../../shared/styles/theme';
import {isRoleMatched} from '../../../shared/utils/user';
import {drawerState} from '../atoms/drawerState';
import {HIDE_MY_CONNECTIONS} from '../constants/flags';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';

import {PatientDetailsDrawerContent} from './patientDetailsDrawerContent';

type Props = {
  title: string;
  crumbs: Crumb[];
  children: ReactNode;
  activeTabIndex: number;
  isLoading: boolean;
};

const DRAWER_WIDTH = 460;

export const PatientsPageWrapper: FC<Props> = ({
  crumbs,
  title,
  activeTabIndex,
  children,
  isLoading,
}) => {
  const history = useHistory();
  const drawer = useRecoilValue(drawerState);

  const currentUser = useRecoilValue<User | null>(currentUserState);

  const TABS: {[key: string]: string} = {
    [PORTAL_PATIENTS_ROUTES.CONNECTIONS]: 'Connections',
    [PORTAL_PATIENTS_ROUTES.INVITATIONS]: 'Invitations',
  };

  if (
    isRoleMatched(currentUser?.roles, [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) &&
    currentUser?.type === USER_TYPES.PHYSICIAN &&
    !HIDE_MY_CONNECTIONS
  ) {
    TABS[PORTAL_PATIENTS_ROUTES.MY_CONNECTIONS] = 'My Connections';
  }

  const addInviteButtonHandle = () => {
    const activePath = Object.keys(TABS)[activeTabIndex];
    history.push(`${activePath}/invitation`);
  };

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      <LoadingBarWrapper>
        <LoadingBar loading={isLoading} />
      </LoadingBarWrapper>
      <Subheader title="Patient List">
        <AddButton
          text="Invite patient"
          onClick={addInviteButtonHandle}
          testID="userInvitationBtn"
        />
      </Subheader>

      <TabsWrapper>
        <Tab
          activeIndex={activeTabIndex}
          onTabChange={(e, value) => {
            history.push(Object.keys(TABS)[value]);
          }}
          tabs={Object.values(TABS)}
        />
      </TabsWrapper>
      <PageGutters>{children}</PageGutters>
      <Drawer drawerWidth={DRAWER_WIDTH}>
        {drawer.isOpen ? (
          <PatientDetailsDrawerContent contentWidth={DRAWER_WIDTH} />
        ) : null}
      </Drawer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TabsWrapper = styled.div`
  margin: 16px 60px 20px 40px;
  border-bottom: 1px solid ${colors.primary};
`;

const PageGutters = styled.div`
  margin: 0 60px 0 40px;
  padding-bottom: 60px;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  min-height: 300px;
`;

const LoadingBarWrapper = styled.div`
  position: relative;
`;
