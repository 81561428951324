import React, {FC, useCallback} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button} from '../../../shared/components/button';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {fetchShareMedicalRecordsWithProvider} from '../../../shared/utils/fetchers';
import {
  CONNECTION_STATUSES,
  MEDICAL_RECORDS_INITIATION_TYPES,
} from '../../portalPatients/constants/patientStatuses';
import {notValidTaskModalWindowState} from '../atoms/notValidTaskModalWindowState';
import {CallBackFuncType, ResolutionEnum} from '../constants/types';
import {useCustomizedButtonStyles} from '../hooks/useCustomizedButtonStyles';
import {
  fetchInboxProviderResolutionEvent,
  fetchPatientConnection,
} from '../utils/fetchers';

type Props = {
  handleRequestActionCallBack: CallBackFuncType;
  eventId: string;
  consumerId: string;
  relyingPartyId: number;
};

export const RequestPatientButton: FC<Props> = ({
  handleRequestActionCallBack,
  eventId,
  consumerId,
  relyingPartyId,
}) => {
  const classes = useCustomizedButtonStyles();
  const queryClient = useQueryClient();
  const snackbar = useCustomizedSnackbar();
  const currentUser = useRecoilValue(currentUserState);
  const setNotValidTaskModalWindowState = useSetRecoilState(
    notValidTaskModalWindowState
  );

  const handleDismissAction = useCallback(async () => {
    try {
      await fetchInboxProviderResolutionEvent(
        ResolutionEnum.DISMISSED,
        eventId
      );
      await queryClient.invalidateQueries({
        queryKey: ['inbox-providers-events'],
      });
    } catch {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
  }, [eventId, queryClient, snackbar]);

  const handleRequestAction = useCallback(async () => {
    try {
      const {share_patient_to_provider, id, status} =
        await fetchPatientConnection(relyingPartyId, consumerId);
      if (
        status !== CONNECTION_STATUSES.CONNECTED ||
        (status === CONNECTION_STATUSES.CONNECTED && share_patient_to_provider)
      ) {
        setNotValidTaskModalWindowState({callBackFunc: handleDismissAction});
      } else {
        await Promise.all([
          await fetchInboxProviderResolutionEvent(
            ResolutionEnum.COMPLETED,
            eventId
          ),
          await fetchShareMedicalRecordsWithProvider({
            relying_party_id: relyingPartyId,
            client_id: currentUser?.id,
            consumer_uuid: consumerId,
            connection_id: id,
            type: MEDICAL_RECORDS_INITIATION_TYPES.CHANGE_CONNECTION,
          }),
        ]);
        await queryClient.invalidateQueries({
          queryKey: ['inbox-providers-events'],
        });
      }
    } catch {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
  }, [
    consumerId,
    currentUser?.id,
    eventId,
    handleDismissAction,
    queryClient,
    relyingPartyId,
    setNotValidTaskModalWindowState,
    snackbar,
  ]);

  const onClick = useCallback(
    () => handleRequestActionCallBack(handleRequestAction),
    [handleRequestAction, handleRequestActionCallBack]
  );

  return (
    <Button
      position="section"
      onClick={onClick}
      data-testid={`inbox-request-btn-${eventId}`}
      variant="outlined"
      className={classes.customizedBtn}
    >
      Request
    </Button>
  );
};
