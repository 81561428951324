import React, {FC, useCallback} from 'react';
import {Link} from '@material-ui/core';
import {useQueryClient} from '@tanstack/react-query';

import {UndoSnackbarContent} from '../../../shared/components/undoSnackbarContent';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {CallBackFuncType, ResolutionEnum} from '../constants/types';
import {
  fetchInboxProviderDismissEvent,
  fetchInboxProviderResolutionEvent,
} from '../utils/fetchers';

type Props = {
  onClickCallBack: CallBackFuncType;
  eventId: string;
  patientName: string;
  eventCategory: string;
};

export const DismissActionButton: FC<Props> = ({
  onClickCallBack,
  eventId,
  eventCategory,
  patientName,
}) => {
  const queryClient = useQueryClient();
  const snackbar = useCustomizedSnackbar();

  const handleUndoAction = useCallback(async () => {
    try {
      await fetchInboxProviderDismissEvent(ResolutionEnum.DISMISSED, eventId);
      await queryClient.invalidateQueries({
        queryKey: ['inbox-providers-events'],
      });
    } catch {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
  }, [eventId, queryClient, snackbar]);

  const handleDismissAction = useCallback(async () => {
    try {
      await fetchInboxProviderResolutionEvent(
        ResolutionEnum.DISMISSED,
        eventId
      );
      await queryClient.invalidateQueries({
        queryKey: ['inbox-providers-events'],
      });
      snackbar(
        <UndoSnackbarContent
          message={`'${eventCategory}' item for ${patientName} dismissed`}
          handleUndo={() => onClickCallBack(handleUndoAction)}
        />,
        'info',
        false
      );
    } catch {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
  }, [
    eventCategory,
    eventId,
    handleUndoAction,
    onClickCallBack,
    patientName,
    queryClient,
    snackbar,
  ]);

  return (
    <Link
      href="/#"
      underline="always"
      onClick={event => {
        event.preventDefault();
        onClickCallBack(handleDismissAction);
      }}
      data-testid={`dismiss-${eventId}`}
    >
      Dismiss
    </Link>
  );
};
