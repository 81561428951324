import React, {FC} from 'react';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {RedirectToFirstAvailableRoute} from '../../../shared/components/redirectToFirstAvailableRoute';
import {PlaceholderComponent} from '../../../shared/components/table/placeholderComponent';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {ROLES} from '../../../shared/constants/roles';
import {isRoleMatched} from '../../../shared/utils/user';
import {usePagination} from '../../portalPatients/components/table/hooks/usePagination';
import {getTotalPages} from '../../portalPatients/utils/getTotalPages';
import {FiltersWrapper} from '../components/filtersWrapper';
import {InboxWrapper} from '../components/inboxWrapper';
import {getDefaultCompletedFiltersValues} from '../constants/defaultFilterValues';
import {COMPLETED_TABLE_COLUMNS} from '../constants/inboxTableConfig';
import {TAB_INDEX} from '../constants/tabIndex';
import {useInboxFilters} from '../hooks/useInboxFilters';
import {useInboxProviderEvents} from '../hooks/useInboxProviderEvents';
import {formatInboxEvents} from '../utils/formatInboxEvents';

type Props = {
  title: string;
};

export const InboxCompletedPage: FC<Props> = ({title}) => {
  const currentUser = useRecoilValue(currentUserState);

  const {setPagination, pagination, resetPagination, nextPage} =
    usePagination();

  const {
    filterValues,
    setFilterValues,
    handleToggleCategoryValue,
    handleToggleProviderValue,
    handleTogglePatientValue,
    handleToggleDateRange,
    handleToggleExpiredRequests,
    handleClearFilters,
  } = useInboxFilters(
    getDefaultCompletedFiltersValues(currentUser),
    resetPagination
  );

  const {
    data: {events: inboxProviderEventsData, count: countOfPages},
    isLoading: inboxProviderEventsLoading,
    isFetching: inboxProviderEventsFetching,
  } = useInboxProviderEvents(filterValues, pagination, nextPage);

  const nothingFoundCase =
    !inboxProviderEventsLoading && inboxProviderEventsData.length === 0;

  const isRedirect = !isRoleMatched(currentUser?.roles, [
    ROLES.RELYING_PARTY_OWNER,
    ROLES.RELYING_PARTY_ADMIN,
    ROLES.RELYING_PARTY_RECORDS_REQUESTER,
    ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
  ]);

  const table = useReactTable({
    data: formatInboxEvents(inboxProviderEventsData),
    columns: COMPLETED_TABLE_COLUMNS,
    pageCount: getTotalPages(countOfPages),
    state: {
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return (
    <>
      {isRedirect && <RedirectToFirstAvailableRoute />}
      <InboxWrapper
        title={title}
        handleToggleExpiredRequests={handleToggleExpiredRequests}
        isLoading={inboxProviderEventsFetching}
        activeTabIndex={TAB_INDEX.SECOND}
        categoryEvents={filterValues.eventCategories}
        filterValues={filterValues}
        setSelectedFilterValues={setFilterValues}
        resetPagination={resetPagination}
      >
        <FiltersWrapper
          filterValues={filterValues}
          handleToggleCategoryValue={handleToggleCategoryValue}
          handleToggleProviderValue={handleToggleProviderValue}
          handleTogglePatientValue={handleTogglePatientValue}
          handleToggleDateRange={handleToggleDateRange}
          handleClearFilters={handleClearFilters}
        />

        {!inboxProviderEventsLoading && (
          <StyledTable
            isHeaderSticky
            table={table}
            testId="completed-table"
            placeholderComponent={
              nothingFoundCase ? (
                <PlaceholderComponent searchError="No results found. Please try another filter options." />
              ) : null
            }
            withPagination
          />
        )}
      </InboxWrapper>
    </>
  );
};
