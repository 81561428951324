import React from 'react';
import {Box} from '@material-ui/core';
import styled from 'styled-components';

import {colors} from '../../../../shared/styles/theme';

type Props = {
  status: string;
};

export const RecordOrderStatus = ({status}: Props): JSX.Element => {
  const isCompleted = status === 'UPDATE_RECEIVED';

  return (
    <Box>
      <StatusLabel color={isCompleted ? colors.green : colors.grey801}>
        {isCompleted ? 'Completed' : status.toLowerCase()}
      </StatusLabel>
    </Box>
  );
};

const StatusLabel = styled.p`
  font-size: 14px;
  color: ${props => props?.color};
  font-weight: 700;
  text-transform: capitalize;
`;
