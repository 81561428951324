import React from 'react';
import {ColumnDef} from '@tanstack/react-table';

import {NoDataDash} from '../../../shared/components/noDataDash';
import {AdditionalTableProps} from '../../../shared/interfaces/table';
import {formatDate} from '../components/electronicRecordTab/utils/mappersUtils';
import {RecordOrderStatus} from '../components/table/recordOrderStatus';
import {chooseProviderDisplayNames} from '../utils/chooseProviderName';
import {getProviderDirectoryAddress} from '../utils/getProviderDirectoryAddress';

import {MedicalRecordsRequestType} from './types';

export const getViewOrdersTableCells =
  (): (ColumnDef<MedicalRecordsRequestType> & AdditionalTableProps)[] => [
    {
      id: 'connectedProviders',
      header: 'Connected Providers',
      width: '240px',
      minWidth: '240px',
      isStickyLeft: true,
      cell({row: {original}}) {
        const {nameDisplay} = chooseProviderDisplayNames(original);
        return nameDisplay;
      },
    },
    {
      id: 'organization',
      header: 'Organization',
      cell({row: {original}}) {
        const {organizationDisplay} = chooseProviderDisplayNames(original);
        return organizationDisplay ?? <NoDataDash />;
      },
      width: '200px',
      minWidth: '200px',
    },
    {
      id: 'address',
      header: 'Address',
      cell({row: {original}}) {
        const address = original.provider_directory_location
          ? getProviderDirectoryAddress(original.provider_directory_location)
          : undefined;

        if (!address?.firstLine && !address?.secondLine) {
          return <NoDataDash />;
        }
        return (
          <>
            {address?.firstLine && (
              <>
                {address.firstLine}
                <br />
              </>
            )}
            {address?.secondLine}
          </>
        );
      },
      width: '240px',
      minWidth: '240px',
    },
    {
      id: 'orderType',
      header: 'Order Type',
      cell({row: {original}}) {
        const recordTypeDisplay =
          original.document_type === 'IMAGE' ? 'Images' : 'Medical Records';
        return recordTypeDisplay ?? <NoDataDash />;
      },
      width: '180px',
      minWidth: '180px',
    },
    {
      id: 'deliveryMethod',
      header: 'Delivery Method',
      cell({row: {original}}) {
        const isFHIR = original?.type?.includes('FHIR');
        if (!original.type) {
          return <NoDataDash />;
        }
        return isFHIR ? 'Digital Download' : 'Standard Delivery';
      },
      width: '180px',
      minWidth: '180px',
    },
    {
      id: 'orderedBy',
      header: 'Ordered By',
      cell({row: {original}}) {
        return original.ordered_by;
      },
      width: '180px',
      minWidth: '180px',
    },
    {
      id: 'name',
      header: 'Date Ordered',
      cell({row: {original}}) {
        return formatDate(original.created_at) ?? <NoDataDash />;
      },
      width: '200px',
      minWidth: '200px',
    },
    {
      id: 'dateCompleted',
      header: 'Date Completed',
      cell({row: {original}}) {
        return formatDate(original.updated_at) ?? <NoDataDash />;
      },
      width: '200px',
      minWidth: '200px',
    },
    {
      id: 'orderStatus',
      header: 'Order Status',
      width: '180px',
      minWidth: '180px',
      isStickyRight: true,
      cell({row: {original}}) {
        return original?.status ? (
          <RecordOrderStatus status={original?.status} />
        ) : (
          <NoDataDash />
        );
      },
    },
  ];
