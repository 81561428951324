import React, {FC} from 'react';
import styled from 'styled-components';

import smthWentWrongIcon from '../../../../assets/icons/smthWentWrongIcon.svg';
import {colors} from '../../../shared/styles/theme';

export const PermissionDenied: FC = () => (
  <Container>
    <img src={smthWentWrongIcon} alt="smthWentWrongIcon" />
    <LabelText>You do not have permission to view record orders.</LabelText>
    <LabelText>
      Please instruct patient to turn on Authorize Assistance{' '}
    </LabelText>
    <LabelText>in their Health Bank One mobile app.</LabelText>
  </Container>
);

const Container = styled.div`
  text-align: center;
`;

const LabelText = styled.p`
  font-style: italic;
  line-height: 12px;
  color: ${colors.grey801};
`;
