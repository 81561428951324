import {useRecoilValue, useSetRecoilState} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {confirmDisconnectPatientState} from '../../../shared/atoms/confirmDisconnectPatientState';
import {isOpenDisconnectionAccessDeniedModalState} from '../../../shared/atoms/isOpenDisconnectionAccessDeniedModalState';
import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {isRoleMatched} from '../../../shared/utils/user';

export const useConfirmDisconnectPatient = (
  isPatientConnected,
  isSharingMedicalRecords,
  connectionId
) => {
  const {roles, type} = useRecoilValue(currentUserState);
  const openConfirmModal = useSetRecoilState(confirmDisconnectPatientState);
  const openDisconnectionAccessDeniedModal = useSetRecoilState(
    isOpenDisconnectionAccessDeniedModalState
  );

  const isPhysicianHasRightRolesForDisconnectPatient =
    isRoleMatched(roles, [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) &&
    USER_TYPES.PHYSICIAN === type &&
    isPatientConnected &&
    isSharingMedicalRecords;

  const isNonPhysicianHasRightRolesForViewRecords =
    isRoleMatched(roles, [
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) &&
    USER_TYPES.NON_PHYSICIAN === type &&
    isPatientConnected &&
    isSharingMedicalRecords;

  return (patientUuid, patientName) => {
    if (
      isNonPhysicianHasRightRolesForViewRecords ||
      isPhysicianHasRightRolesForDisconnectPatient
    ) {
      openConfirmModal({
        isOpen: true,
        data: {
          connectionId,
          patientName,
        },
      });
    } else {
      openDisconnectionAccessDeniedModal(true);
    }
  };
};
