import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import activityLogIcon from '../../../assets/icons/activityLog.svg';
import entryIcon from '../../../assets/icons/entry.svg';
import inboxIcon from '../../../assets/icons/inbox.svg';
import inviteUser from '../../../assets/icons/inviteUser.svg';
import locationIcon from '../../../assets/icons/location.svg';
import menuRelyingPartyManagement from '../../../assets/icons/menuRelyingPartyManagement.svg';
import notificationIcon from '../../../assets/icons/notifications.svg';
import policyIcon from '../../../assets/icons/policy.svg';
import portalPatientManagementIcon from '../../../assets/icons/portalPatientManagement.svg';
import portalUserIcon from '../../../assets/icons/portalUserIcon.svg';
import portalUserManagementIcon from '../../../assets/icons/portalUserManagement.svg';
import relyingParties from '../../../assets/icons/relyingParties.svg';
import reportsIcon from '../../../assets/icons/reports.svg';
import {ROLES} from '../../shared/constants/roles';
import {PORTAL_PATIENTS_ROUTES} from '../portalPatients/constants/routes';

import {Aside} from './components/aside';
import {Header} from './components/header';

const menu = [
  {
    title: 'Notifications',
    prerequisiteRoles: [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ],
    children: [
      {
        title: 'Inbox',
        path: '/inbox/to-do',
        icon: inboxIcon,
      },
    ],
    icon: notificationIcon,
  },
  {
    title: 'Patient management',
    icon: portalPatientManagementIcon,
    prerequisiteRoles: [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ],
    children: [
      {
        title: 'Patients',
        path: PORTAL_PATIENTS_ROUTES.CONNECTIONS,
        icon: inviteUser,
      },
    ],
  },
  {
    title: 'Reports',
    prerequisiteRoles: [
      ROLES.ISSUER_OWNER,
      ROLES.ISSUER_ADMIN,
      ROLES.ISSUER_SUPPORT_AGENT,
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
      ROLES.SCREENER,
    ],
    children: [
      {
        title: 'Activity log',
        path: '/activity-log',
        icon: activityLogIcon,
      },
    ],
    icon: reportsIcon,
  },
  {
    title: 'Relying party management',
    icon: menuRelyingPartyManagement,
    prerequisiteRoles: [
      ROLES.ISSUER_OWNER,
      ROLES.ISSUER_ADMIN,
      ROLES.ISSUER_SUPPORT_AGENT,
    ],
    children: [
      {
        title: 'Relying parties',
        path: '/relying-parties',
        icon: relyingParties,
      },
    ],
  },
  {
    title: 'Location management',
    icon: entryIcon,
    prerequisiteRoles: [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
    ],
    children: [
      {
        title: 'Locations',
        path: '/locations',
        icon: locationIcon,
      },
      {
        title: 'Policies',
        path: '/health-policies',
        icon: policyIcon,
      },
    ],
  },
  {
    title: 'Portal user management',
    icon: portalUserManagementIcon,
    prerequisiteRoles: [
      ROLES.ISSUER_OWNER,
      ROLES.ISSUER_ADMIN,
      ROLES.ISSUER_SUPPORT_AGENT,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_OWNER,
    ],
    children: [
      {
        title: 'Portal users',
        path: '/portal-users',
        icon: portalUserIcon,
      },
    ],
  },
];

export const Layout = ({children}) => (
  <LayoutContainer>
    <Header />
    <Body id="page-body">
      <Aside menu={menu} />
      <Content>{children}</Content>
    </Body>
  </LayoutContainer>
);
Layout.propTypes = {
  children: PropTypes.node,
};

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  align-items: center;
  transition: 0.6s;
`;
