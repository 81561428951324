import {useQuery} from '@tanstack/react-query';

import {MedicalRecordsRequestType} from '../constants/types';
import {fetchMedicalRecordsOrders} from '../utils/fetchers';

type HookReturn = {
  data: MedicalRecordsRequestType[];
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
  error: any;
};

const getMedicalRecordsOrders = async (
  consumerUuid: string
): Promise<MedicalRecordsRequestType[]> => {
  const data = await fetchMedicalRecordsOrders(consumerUuid);
  return data;
};

export const useMedicalRecrodsOrders = (consumerUUID: string): HookReturn => {
  const {
    data = [],
    isLoading,
    refetch,
    isError,
    error,
  } = useQuery({
    queryKey: ['medicalRecordsOrders', consumerUUID],
    queryFn: () => getMedicalRecordsOrders(consumerUUID),
    keepPreviousData: true,
    refetchOnMount: true,
  });
  return {data, isLoading, refetch, isError, error};
};
