import {fetcher, HttpMethod} from '../../../shared/utils/fetcher';
import {ResponseType} from '../../../shared/utils/responseType';
import {urls} from '../../../shared/utils/urls';

import {parseFileData} from './fileViewer';

export const fetchPhysiciansUUIDS = (relyingPartyId, physiciansIDS) =>
  fetcher(urls.physiciansUUIDS(relyingPartyId), HttpMethod.POST, physiciansIDS);

export const fetchEnrolledPatientsBySearchOptions = (
  relyingPartyId,
  searchValue,
  physiciansUUIDS,
  selectedStatuses
) =>
  fetcher(urls.enrolledPatients(relyingPartyId), HttpMethod.POST, {
    search_phrase: searchValue,
    consumer_uuids: physiciansUUIDS,
    connection_statuses: selectedStatuses,
  });

export const fetchMyPatientsConsumerUUIDS = relyingPartyId =>
  fetcher(urls.myPatientsConsumerUuids(relyingPartyId));

export const fetchPhysicians = relyingPartyId =>
  fetcher(urls.physicians(relyingPartyId));

export const fetchMyPatientsConsumerUuids = relyingPartyId =>
  fetcher(urls.myPatientsConsumerUuids(relyingPartyId));

export const fetchMyPatientsList = (
  relyingPartyId,
  searchValue,
  selectedConnectionStatuses
) =>
  fetcher(urls.myPatients(relyingPartyId), HttpMethod.POST, {
    search_phrase: searchValue,
    connection_statuses: selectedConnectionStatuses,
  });

export const fetchPatientDetails = (consumerUUID, relyingPartyId) =>
  fetcher(urls.patientDetails(consumerUUID, relyingPartyId));

export const fetchElectronicRecordSectionData = url => fetcher(url);

export const fetchPatientLimitedDetails = (consumerUUID, relyingPartyId) =>
  fetcher(urls.patientLimitedDetails(consumerUUID, relyingPartyId));

export const fetchAddMyPatient = (relyingPartyId, uuid) =>
  fetcher(urls.createMyPatient(relyingPartyId, uuid), HttpMethod.POST);

export const fetchPatientMedicalRecordsRequestsListByIds = data =>
  fetcher(
    urls.fetchPatientMedicalRecordsRequestsListByIds(),
    HttpMethod.POST,
    data
  );

export const fetchRemoveMyPatient = (relyingPartyId, uuid) =>
  fetcher(urls.removeMyPatient(relyingPartyId, uuid), HttpMethod.DELETE);

export const fetchFileUploadLink = data =>
  fetcher(urls.fileUploadLink(), HttpMethod.POST, data);

export const fetchFileUploadSave = data =>
  fetcher(urls.fileUploadSave(), HttpMethod.POST, data);

export const fetchOutstandingRecordRequests = (relyingPartyId, consumerUuid) =>
  fetcher(urls.outstandingRecordRequests(relyingPartyId, consumerUuid));

export const fetchEHRs = relyingPartyId =>
  fetcher(urls.relyingPartyEhrs(relyingPartyId));

export const fetchAddMRN = (consumerUuid, data) =>
  fetcher(urls.addMRN(consumerUuid), HttpMethod.POST, data);

export const fetchUpdateMRN = (consumerUuid, mrnId, data) =>
  fetcher(urls.updateMRN(consumerUuid, mrnId), HttpMethod.PATCH, data);

export const fetchDeleteMRN = (consumerUuid, mrnId) =>
  fetcher(urls.deleteMRN(consumerUuid, mrnId), HttpMethod.DELETE);

export const fetchMedicalDocuments = (
  consumerUuid,
  sortDirection,
  fileTypes,
  page,
  itemsOnPage
) =>
  fetcher(
    urls.medicalDocuments(
      consumerUuid,
      sortDirection,
      fileTypes,
      page,
      itemsOnPage
    )
  );

export const fetchFileUpload = (uploadUrl, fileForPreview) =>
  fetch(uploadUrl, {
    method: HttpMethod.PUT,
    headers: {
      'Content-Type': fileForPreview.contentType.headerContentType,
    },
    body: fileForPreview.file,
  });

export const fetchFileForPreview = fileForPreview =>
  fetcher(urls.filePreview(), HttpMethod.POST, fileForPreview, {
    responseDataParser: parseFileData,
    responseType: fileForPreview.content_type,
  });

export const fetchDownloadFilesUrls = filesForDownload =>
  fetcher(urls.fileDownload(), HttpMethod.POST, filesForDownload);

export const fetchDownloadFile = downloadUrl =>
  fetch(downloadUrl, {
    method: HttpMethod.GET,
  });

export const fetchPatientDocument = url =>
  fetcher(url, HttpMethod.GET, null, {responseType: ResponseType.Blob});

export const disconnectPatient = connectionId =>
  fetcher(urls.deleteConnection(connectionId), HttpMethod.DELETE);

export const fetchAIChat = (
  consumerUuid,
  message,
  conversationId,
  fileRequest
) =>
  fetcher(
    urls.getAIChat(consumerUuid),
    HttpMethod.POST,
    {
      message,
      conversation_id: conversationId,
      file_request: fileRequest,
    },
    {
      timeout: 120000,
    }
  );

export const fetchAIChatFileLink = (consumerUuid, fileName) =>
  fetcher(
    urls.getAIChatFileLink(consumerUuid),
    HttpMethod.POST,
    {file_name: fileName},
    {
      timeout: 120000,
    }
  );

export const saveAIChatFileInLink = (
  uploadUrl,
  contentType,
  maxContentLength,
  file
) =>
  fetch(uploadUrl, {
    method: HttpMethod.PUT,
    headers: {
      'Content-Type': contentType,
      'x-goog-content-length-range': [0, maxContentLength],
    },
    body: file,
  });

export const fetchAIChatHistory = consumerUuid =>
  fetcher(urls.getAIChatHistory(consumerUuid), HttpMethod.GET);

export const fetchAIConversation = conversationId =>
  fetcher(urls.getAIConversation(conversationId), HttpMethod.GET);

export const fetchDeleteAIConversation = conversationId =>
  fetcher(urls.getAIConversation(conversationId), HttpMethod.DELETE);

export const fetchMedicalRecordsOrders = consumerUuid =>
  fetcher(urls.getMedicalRecordsOrders(consumerUuid), HttpMethod.GET);
