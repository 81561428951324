import React, {FC, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import {RedDotWrapper} from '../../../shared/components/redDotWrapper';
import {InboxIcon} from '../../../shared/icons/InboxIcon';
import {colors} from '../../../shared/styles/theme';
import {INBOX_ROUTES} from '../constants/inboxRoutes';
import {useInboxNewEventsCount} from '../hooks/useInboxNotifications';

export const InboxButton: FC = () => {
  const {pathname} = useLocation();
  const history = useHistory();
  const {data: newEventsNumber} = useInboxNewEventsCount();

  const isActive = () => {
    const routes = Object.values(INBOX_ROUTES).map(value => value.toString());
    return routes.includes(pathname);
  };

  const onClick = useCallback(
    () => history.push(INBOX_ROUTES.TODO_ROUTE),
    [history]
  );

  return (
    <StyledButton
      data-testid="inbox-btn"
      active={isActive().toString()}
      startIcon={
        <RedDotWrapper isActive={Boolean(newEventsNumber)}>
          <InboxIcon />
        </RedDotWrapper>
      }
      onClick={onClick}
    >
      Inbox
    </StyledButton>
  );
};

const StyledButton = styled(Button).attrs((props: {active: string}) => props)`
  color: ${colors.white};
  margin-right: 12px;
  height: 36px;
  text-transform: none;
  line-height: 24px;
  font-size: 14px;
  box-shadow: none;
  font-weight: 400;
  min-width: 110px;
  background-color: ${props =>
    props.active === 'true' ? colors.darkPrimary : colors.blue};
  &:hover {
    background-color: ${colors.lightBlue};
  }
  pointer-events: ${props => (props.active === 'true' ? 'none' : 'auto')};
`;
