import React, {FC, useCallback, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import {envConfig} from '../../../../env';
import {currentUserState} from '../../../shared/atoms/authAtom';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {Drawer} from '../../../shared/components/drawer';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {RedirectToFirstAvailableRoute} from '../../../shared/components/redirectToFirstAvailableRoute';
import {Tab} from '../../../shared/components/tab';
import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {CareGuideChatIcon} from '../../../shared/icons/careGuideChatIcon';
import {Crumb} from '../../../shared/interfaces/crumb';
import {colors} from '../../../shared/styles/theme';
import {isRoleMatched} from '../../../shared/utils/user';
import {drawerState} from '../atoms/drawerState';
import {selectedPatientState} from '../atoms/selectedPatientState';
import {ChatDrawer} from '../components/aIChat/chatDrawer';
import useMessages from '../components/aIChat/hooks/useMessages';
import TermsAndCondition from '../components/aIChat/termsAndCondition';
import {PatientPageHeader} from '../components/patientPageHeader';
import {TabContentSwitcher} from '../components/tabContentSwitcher';
import {CONNECTION_STATUSES} from '../constants/patientStatuses';
import {TAB_INDEX} from '../constants/tabIndex';
import {usePatientDetails} from '../hooks/usePatientDetails';
import {getTabs, PossibleTab} from '../utils/getTabs';

const DRAWER_WIDTH = 460;

const getAllPossibleTabs = (): PossibleTab[] => [
  {
    key: 'electronic-record',
    title: 'Medical Summary',
    path: 'electronic-record/allergies',
    isHidden: false,
  },
  {
    key: 'medical-documents',
    title: 'Medical Files',
    path: 'medical-documents',
    isHidden: false,
  },
  {
    key: 'id-and-insurance-cards',
    title: 'ID & Insurance Cards',
    path: 'id-and-insurance-cards',
    isHidden: false,
  },
  {
    key: 'view-orders',
    title: 'View Orders',
    path: 'view-orders',
    isHidden: !envConfig.REACT_APP_VIEW_ORDERS_TAB_ENABLED,
  },
];

type Props = {
  crumbs: Crumb[];
  title: string;
  location: string;
};

export const PatientProfilePage: FC<Props> = ({crumbs, title, location}) => {
  const history = useHistory();
  const {patientUuid, patientTab} = useParams<{
    patientUuid: string;
    patientTab: string;
  }>();
  const {handleClickNewChat} = useMessages();
  const selectedPatient = useRecoilValue(selectedPatientState);
  const currentUser = useRecoilValue(currentUserState);

  const TABS = getTabs(getAllPossibleTabs());

  const [activeTabIndex, setActiveTabIndex] = useState<TAB_INDEX>(
    TABS[patientTab].tabIndex || TAB_INDEX.FIRST
  );
  const drawer = useRecoilValue(drawerState);
  const setDrawerState = useSetRecoilState(drawerState);

  const {data: patientDetails, isLoading: isPatientDetailsLoading} =
    usePatientDetails(patientUuid, currentUser?.relyingParty.id ?? null);

  const isPatientConnected =
    patientDetails?.connection?.status === CONNECTION_STATUSES.CONNECTED;

  const isSharingMedicalRecords =
    patientDetails?.connection?.share_patient_to_provider;

  const isPhysicianHasRightRolesForViewRecords =
    isRoleMatched(currentUser?.roles, [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) &&
    USER_TYPES.PHYSICIAN === currentUser?.type &&
    isPatientConnected &&
    isSharingMedicalRecords;

  const isNonPhysicianHasRightRolesForViewRecords =
    isRoleMatched(currentUser?.roles, [
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) &&
    USER_TYPES.NON_PHYSICIAN === currentUser?.type &&
    isPatientConnected &&
    isSharingMedicalRecords;

  const isShowDetails =
    !isPatientDetailsLoading &&
    patientDetails &&
    (isPhysicianHasRightRolesForViewRecords ||
      isNonPhysicianHasRightRolesForViewRecords);

  const isRedirect = () => {
    if (
      !isPatientDetailsLoading &&
      patientDetails &&
      USER_TYPES.NON_PHYSICIAN === currentUser?.type
    ) {
      return !isNonPhysicianHasRightRolesForViewRecords;
    }

    if (
      !isPatientDetailsLoading &&
      patientDetails &&
      USER_TYPES.PHYSICIAN === currentUser?.type
    ) {
      return !isPhysicianHasRightRolesForViewRecords;
    }

    return false;
  };

  const onTabChange = useCallback(
    (_, value) => {
      setActiveTabIndex(value);
      history.push(
        `/portal-patients/${location}/${patientUuid}/${
          Object.values(TABS)[value].path
        }`
      );
    },
    [TABS, history, location, patientUuid]
  );

  const chatFeature = !drawer.isOpen ? (
    <ChatMenuLogo
      data-testid="ai-chat-button"
      onClick={() => {
        handleClickNewChat();
        setDrawerState(prevState => ({...prevState, isOpen: true}));
      }}
    >
      <CareGuideChatIcon />
    </ChatMenuLogo>
  ) : (
    // </ChatMenu>
    <Drawer drawerWidth={DRAWER_WIDTH} data-testid="ai-chat-drawer">
      <ChatDrawer contentWidth={DRAWER_WIDTH} />
    </Drawer>
  );
  const handleRejectionTermAndCondition = () => {
    setDrawerState(prevState => ({...prevState, isOpen: false}));
  };

  return (
    <Container>
      {selectedPatient.patientName && (
        <BreadCrumbNavigator crumbs={crumbs} title={title} />
      )}
      {chatFeature}
      <TermsAndCondition
        isOpen={
          drawer.isOpen &&
          localStorage.getItem('agreedTermsAndConditions') !== 'true'
        }
        handleClose={handleRejectionTermAndCondition}
      />
      {selectedPatient.patientName &&
        isPatientDetailsLoading &&
        !patientDetails && <LoadingBar loading={isPatientDetailsLoading} />}
      {isRedirect() && <RedirectToFirstAvailableRoute />}
      {isShowDetails && (
        <>
          <HeaderWrapper data-testid="patient-details-header">
            <PatientPageHeader patientsProfileDetails={patientDetails} />
          </HeaderWrapper>
          <TabsContentWrapper>
            <TabsWrapper>
              <Tab
                activeIndex={activeTabIndex}
                onTabChange={onTabChange}
                tabs={Object.values(TABS).map(el => el.title)}
              />
            </TabsWrapper>
            <TabContentSwitcher
              testId={Object.keys(TABS)[activeTabIndex]}
              consumerUuid={patientDetails.publicDetails.consumerUuid}
              primaryInsuranceId={
                patientDetails.publicDetails.primaryInsuranceId
              }
              secondaryInsuranceId={
                patientDetails.publicDetails.secondaryInsuranceId
              }
              patientFirstName={patientDetails.publicDetails.firstName}
              patientLastName={patientDetails.publicDetails.lastName}
            />
          </TabsContentWrapper>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const TabsWrapper = styled.div`
  border-bottom: 1px solid ${colors.primary};
`;

const TabsContentWrapper = styled.div`
  margin: 16px 60px 0 40px;
`;

const HeaderWrapper = styled.div`
  margin: 24px 60px 40px 40px;
`;

const ChatMenuLogo = styled.div`
  flex-shrink: 0;
  position: absolute;
  right: 0px;
  top: 104px;
`;
