import {useQuery} from '@tanstack/react-query';

import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {wrapAsyncFunction} from '../../../shared/utils/wrapAsyncFunction';
import {fetchPatientMedicalRecordsRequestsListByIds} from '../../portalPatients/utils/fetchers';
import {EVENTS_CATEGORY, ProviderInboxEventsType} from '../constants/types';
import {fetchRequestedRecordInfo} from '../utils/fetchers';

type ReturnHookType = {
  requestedRecordTypeIsLoading: boolean;
  requestedRecordType: EVENTS_CATEGORY | null;
  eventDetails: ProviderInboxEventsType['event_details'] | null;
  isError: boolean;
};

const getRequestedRecordInfo = async (
  requestedEventId: string | null
): Promise<ProviderInboxEventsType> => {
  try {
    const inboxEvent = await fetchRequestedRecordInfo(requestedEventId);
    const eventDetails: ProviderInboxEventsType['event_details'][] =
      await fetchPatientMedicalRecordsRequestsListByIds([
        inboxEvent.event_entity_id,
      ]);

    const currentEventDetails = eventDetails[0];

    return {
      ...inboxEvent,
      event_details: {
        patient_comment: currentEventDetails?.patient_comment,
        date_to: currentEventDetails?.date_to,
        date_from: currentEventDetails?.date_from,
        is_maximum_date_range: currentEventDetails?.is_maximum_date_range,
      },
    };
  } catch (e) {
    throw new Error('Something went wrong. Please try again later.');
  }
};
export const useRequestedRecordInfo = (
  eventId: string | null
): ReturnHookType => {
  const handleError = useErrorHandling();

  const {data, isFetching, isError} = useQuery({
    queryKey: ['inbox-get-requested-record-info', eventId],
    queryFn: () => getRequestedRecordInfo(eventId),
    onError: wrapAsyncFunction(async e => {
      await handleError(e);
    }),
    enabled: !!eventId,
  });

  return {
    requestedRecordType: data?.event_category ?? null,
    eventDetails: data?.event_details ?? null,
    requestedRecordTypeIsLoading: isFetching,
    isError,
  };
};
