import {useRecoilValue, useSetRecoilState} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {confirmViewMedicalRecordsState} from '../../../shared/atoms/confirmViewMedicalRecordsState';
import {isOpenAccessDeniedModalState} from '../../../shared/atoms/isOpenAccessDeniedModalState';
import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {isRoleMatched} from '../../../shared/utils/user';

export const useConfirmViewRecords = (
  isPatientConnected,
  isSharingMedicalRecords
) => {
  const {roles, type} = useRecoilValue(currentUserState);
  const openConfirmModal = useSetRecoilState(confirmViewMedicalRecordsState);
  const openAccessDeniedModal = useSetRecoilState(isOpenAccessDeniedModalState);

  const isPhysicianHasRightRolesForViewRecords =
    isRoleMatched(roles, [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) &&
    USER_TYPES.PHYSICIAN === type &&
    isPatientConnected &&
    isSharingMedicalRecords;

  const isNonPhysicianHasRightRolesForViewRecords =
    isRoleMatched(roles, [
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) &&
    USER_TYPES.NON_PHYSICIAN === type &&
    isPatientConnected &&
    isSharingMedicalRecords;

  return (patientUuid, patientName) => {
    if (
      isNonPhysicianHasRightRolesForViewRecords ||
      isPhysicianHasRightRolesForViewRecords
    ) {
      openConfirmModal({
        isOpen: true,
        data: {
          patientUuid,
          patientName,
        },
      });
    } else {
      openAccessDeniedModal(true);
    }
  };
};
