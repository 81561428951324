import React, {FC, ReactNode} from 'react';
import {useHistory} from 'react-router-dom';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import styled from 'styled-components';

import {LoadingBar} from '../../../shared/components/loadingBar';
import {Subheader} from '../../../shared/components/subheader';
import {Tab} from '../../../shared/components/tab';
import {colors} from '../../../shared/styles/theme';
import {notExpiredEventsCategory} from '../constants/defaultFilterValues';
import {EVENTS_CATEGORY_DROPDOWN, filterValuesType} from '../constants/types';

type Props = {
  title: string;
  activeTabIndex: number;
  handleToggleExpiredRequests: (val: EVENTS_CATEGORY_DROPDOWN[]) => void;
  isLoading: boolean;
  categoryEvents: EVENTS_CATEGORY_DROPDOWN[];
  children: ReactNode;
  setSelectedFilterValues: (val: filterValuesType) => void;
  filterValues: filterValuesType;
  resetPagination: () => void;
};

export const InboxWrapper: FC<Props> = ({
  title,
  activeTabIndex,
  handleToggleExpiredRequests,
  isLoading,
  categoryEvents,
  children,
  filterValues,
  setSelectedFilterValues,
  resetPagination,
}) => {
  const history = useHistory();

  const isCheckboxChecked = () => {
    if (categoryEvents.length !== notExpiredEventsCategory.length) {
      return false;
    }
    return categoryEvents.every(el => notExpiredEventsCategory.includes(el));
  };

  const handleOnClick = async () => {
    resetPagination();
    if (!isCheckboxChecked()) {
      setSelectedFilterValues({
        ...filterValues,
        eventCategories: notExpiredEventsCategory,
      });
      handleToggleExpiredRequests(notExpiredEventsCategory);
    } else {
      setSelectedFilterValues({
        ...filterValues,
        eventCategories: [],
      });
      handleToggleExpiredRequests([]);
    }
  };

  const TABS: {[key: string]: string} = {
    '/inbox/to-do': 'To Do',
    '/inbox/completed': 'Completed',
  };

  return (
    <Container>
      <BreadCrumbsWrapper>
        <Breadcrumbs aria-label="breadcrumb" separator={null}>
          <Title>{title}</Title>
        </Breadcrumbs>
      </BreadCrumbsWrapper>
      <LoadingBar loading={isLoading} />
      <Subheader title={title} />
      <TabsWrapper>
        <Tab
          activeIndex={activeTabIndex}
          onTabChange={(_, value) => {
            history.push(Object.keys(TABS)[value]);
          }}
          tabs={Object.values(TABS)}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="Hide expired requests"
              checked={isCheckboxChecked()}
              onClick={handleOnClick}
            />
          }
          label="Hide expired requests"
        />
      </TabsWrapper>
      <TableWrapper>{children}</TableWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const BreadCrumbsWrapper = styled.div`
  background-color: ${colors.grey40};
  padding-left: 40px;
  height: auto;
`;

const Title = styled.p`
  font-weight: bold;
`;

const TabsWrapper = styled.div`
  border-bottom: 1px solid ${colors.primary};
  display: flex;
  justify-content: space-between;
  margin: 16px 60px 20px 40px;
`;

const TableWrapper = styled.div`
  margin: 0 60px 0 40px;
  overflow-y: auto;
  min-height: 300px;
  display: flex;
  flex-direction: column;
`;
