import React, {FC} from 'react';

import {
  CallBackFuncType,
  EVENTS_CATEGORY,
  TABLE_EVENT_ENUM,
} from '../constants/types';

import {ConnectPatientButton} from './connectPatientButton';
import {InvitePatientButton} from './invitePatientButton';
import {RequestPatientButton} from './requestPatientButton';
import {UploadMedicalRecordsButton} from './uploadMedicalRecordsButton';
import {ViewMedicalRecordsButton} from './viewMedicalRecordsButton';

type Props = {
  eventId: string;
  invitationId: number;
  eventCategory: EVENTS_CATEGORY;
  consumerId: string;
  relyingPartyId: number;
  patientFirstname: string;
  clientId: number;
  eventEntityType: TABLE_EVENT_ENUM;
  onClickCallBack: CallBackFuncType;
};

export const ActionButton: FC<Props> = ({
  eventId,
  invitationId,
  eventCategory,
  consumerId,
  relyingPartyId,
  patientFirstname,
  clientId,
  eventEntityType,
  onClickCallBack,
}) => {
  const renderActionButton = () => {
    switch (eventCategory) {
      case EVENTS_CATEGORY.PATIENT_INVITATION_EXPIRED:
        return (
          <InvitePatientButton
            handleInviteActionCallBack={onClickCallBack}
            eventId={eventId}
            invitationId={invitationId}
          />
        );
      case EVENTS_CATEGORY.PATIENT_DENIED_CONNECTION_REQUEST:
      case EVENTS_CATEGORY.PATIENT_DISCONNECTED:
      case EVENTS_CATEGORY.PATIENT_CREATE_CONNECTION_REQUEST_EXPIRED:
        return (
          <ConnectPatientButton
            eventId={eventId}
            consumerId={consumerId}
            relyingPartyId={relyingPartyId}
            patientFirstname={patientFirstname}
            clientId={clientId}
            handleConnectActionCallBack={onClickCallBack}
          />
        );
      case EVENTS_CATEGORY.PATIENT_DENIED_SHARE_REQUEST:
      case EVENTS_CATEGORY.PATIENT_CHANGE_CONNECTION_REQUEST_EXPIRED:
        return (
          <RequestPatientButton
            eventId={eventId}
            consumerId={consumerId}
            relyingPartyId={relyingPartyId}
            handleRequestActionCallBack={onClickCallBack}
          />
        );
      case EVENTS_CATEGORY.PATIENT_REQUESTED_DOCUMENT_UPLOAD:
      case EVENTS_CATEGORY.PATIENT_REQUESTED_DOCUMENT_UPDATE:
      case EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPLOAD:
      case EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPDATE:
      case EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPLOAD:
      case EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPDATE:
        return (
          <UploadMedicalRecordsButton
            consumerId={consumerId}
            eventId={eventId}
            relyingPartyId={relyingPartyId}
            eventCategory={eventCategory}
          />
        );
      case EVENTS_CATEGORY.PATIENT_RECORD_UPDATE_RECEIVED:
        return (
          <ViewMedicalRecordsButton
            consumerId={consumerId}
            eventId={eventId}
            patientName={patientFirstname}
            eventEntityType={eventEntityType}
          />
        );
      default:
        return null;
    }
  };

  return renderActionButton();
};
