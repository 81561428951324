import {
  FormattedMRNsDetailsType,
  MRNDetailsType,
} from '../../../shared/constants/formattedMRNsDetailsType';
import {PatientInvitationStatus} from '../../../shared/interfaces/patient';

import {PATIENT_DETAILS_LABELS} from './PatientDetailsLabels';
import {CONNECTION_STATUSES} from './patientStatuses';

export interface SelectedUserState {
  patientName: string | null;
  patientUuid: string | null;
}

export type DrawerStateType = {
  isOpen: boolean;
  patientUuid: string | null;
};

export enum AvatarSize {
  small = 'small',
  default = 'default',
}

export interface IPatientAvatar {
  src?: string;
  firstName: string;
  lastName: string;
  size?: AvatarSize;
}

export type PublicDetailType = {
  consumerUuid: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  src?: string;
  primaryInsuranceId: number | null;
  secondaryInsuranceId: number | null;
};

export type PublicLimitedDetailType = {
  consumerUuid: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
};

export type PersonalAndAddressDetailsType = {
  title: PATIENT_DETAILS_LABELS;
  value: string;
};

type ConnectionType = {
  id: number;
  status: CONNECTION_STATUSES;
  consumer_uuid: string;
  client_id: number;
  relying_party_id: number;
  share_patient_to_provider: boolean;
  share_provider_to_patient: boolean;
  is_update_requested: false;
  initiating_type: string;
  expires_at: string;
  created_at: Date;
  updated_at: null | Date;
};

export type SelectedPhysicianType = {
  label: string;
  id: number;
};

export type PatientResponseType = {
  email: string;
  first_name: string;
  last_name: string;
  mobile_phone_number: string;
  date_of_birth: Date;
  consumer_uuid: string;
  connection: ConnectionType | null;
  mrn_ehr_responses: MRNDetailsType[];
};

export type PatientFormattedType = {
  name: string;
  firstName: string;
  date_of_birth: Date;
  mobile_phone_number: string;
  isPreferred: boolean;
  consumer_uuid: string;
  medicalRecordNumbers: FormattedMRNsDetailsType[];
  patientSharingRequestData: ConnectionType | null;
  insuranceId: number | null;
};

export type PatientsProfileDetailsResponseType = {
  selfie: string;
  email: string;
  gender: string;
  address: {
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
  };
  consumer_uuid: string;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  date_of_birth: Date;
  mobile_phone: string;
  primary_insurance_id: number | null;
  secondary_insurance_id: number | null;
  mrn_ehr_responses: MRNDetailsType[];
  connection: ConnectionType;
};

export type PatientsProfileLimitedDetailsResponseType = {
  consumer_uuid: string;
  email: string;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  date_of_birth: Date;
  gender: string;
  connection: ConnectionType;
  mrn_ehr_responses: MRNDetailsType[];
};

export type PatientsProfileDetailsFormattedType = {
  publicDetails: PublicDetailType;
  personalDetails: PersonalAndAddressDetailsType[];
  addressDetails: PersonalAndAddressDetailsType[];
  mrnDetails: FormattedMRNsDetailsType[];
  connection: ConnectionType;
};

export type PatientsProfileLimitedDetailsFormattedType = {
  publicDetails: PublicLimitedDetailType;
  personalDetails: PersonalAndAddressDetailsType[];
  mrnDetails: FormattedMRNsDetailsType[];
  connection: ConnectionType;
};

export type SortingDirections = 'ASC' | 'DESC';

export type QueryKeyOnRevalidateType = 'enrolledPatients' | 'myPatients';

export type PatientDataForRequestType = {
  patientName: string | null;
  connectionStatus?: CONNECTION_STATUSES;
  isUpdateRequested?: boolean;
  requestCallBack: (() => Promise<void>) | null;
};

export enum InvitationError {
  patientIsAlreadyConnected = 'PATIENT_ALREADY_CONNECTED',
  patientIsEnrolledWithDifferentName = 'PHONE_NUMBER_ALREADY_REGISTERED',
  patientExists = 'INVITATION_EXISTS',
  patientOptedOut = 'PATIENT_OPTED_OUT',
}

export type MedicalDocument = {
  consumer_uuid: string;
  file_name: string;
  file_size: number;
  file_type: string;
  id: string;
  provider_name: string;
  uploaded_by: string;
  uploaded_file_name: string;
  uploaded_on: string;
};

export type MedicalDocumentsResponseType = {
  count: number;
  files: MedicalDocument[];
};

export const FileTypes: {[k: string]: string[]} = {
  CCDA: ['CCDA'],
  DICOM: ['DICOM'],
  IMAGE: ['JPEG'],
  PDF: ['PDF'],
  PNG: ['PNG'],
  ZIP: ['ZIP'],
  BIN: ['BIN'],
};

export type PreviewDocumentModalWindowStateType = {
  isOpenDialog: boolean;
  data: ReturnDocumentType[] | null;
  title: CardsTitles | null;
};

export enum ResponseStatuses {
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  NO_DOCUMENT = 'NO_DOCUMENT',
  SUCCESS = 'SUCCESS',
}

export enum DocumentKeys {
  driverLicense = 'driverLicense',
  insurancePrimary = 'insurancePrimary',
  insuranceSecondary = 'insuranceSecondary',
}

export type ImageInfoType = {link: string; file: Blob};

export enum EligibilityStatus {
  VALID = 'VALID',
  NOT_VALID = 'NOT_VALID',
  ERROR = 'ERROR',
}

export type DocumentType = {
  id: number | string;
  eligibility_status: EligibilityStatus;
  last_eligibility_check_at: Date;
};

export interface InsuranceType extends DocumentType {
  health_insurance_provider: string;
  member_id_number: string;
  group_number: string;
  member_name: string;
}

export type ReturnDocumentType = {
  image: null | ImageInfoType;
  status: ResponseStatuses;
  subtitle?: string;
  fileName: string;
  data?: DocumentType;
};

export type ReturnDocumentsType = {
  [key in DocumentKeys]: ReturnDocumentType[];
};

export enum CardsTitles {
  DRIVER_LICENSE_TITLE = 'Driver License',
  PRIMARY_INSURANCE_TITLE = 'Primary Insurance',
  SECONDARY_INSURANCE_TITLE = 'Secondary Insurance',
  NO_INSURANCE_TITLE = '',
}

export enum INVITATION_SUCCESS_REQUEST_STATUS {
  INVITATION_WAS_SEND_SUCCESSFULLY = 'INVITATION_WAS_SEND_SUCCESSFULLY',
  CONNECTION_REQUEST_WAS_SEND_SUCCESSFULLY = 'CONNECTION_REQUEST_WAS_SEND_SUCCESSFULLY',
}

export const INVITATION_SUCCESS_REQUEST_STATUS_MESSAGES = {
  [INVITATION_SUCCESS_REQUEST_STATUS.INVITATION_WAS_SEND_SUCCESSFULLY]:
    'Your invitation was sent successfully.',
  [INVITATION_SUCCESS_REQUEST_STATUS.CONNECTION_REQUEST_WAS_SEND_SUCCESSFULLY]:
    'Connection request sent successfully.',
};

export type OnDownloadDocumentFnType = (
  documents: ReturnDocumentType[] | null
) => void;

export enum HANDLE_RESEND_INVITATION_RESPONSE {
  PATIENT_ALREADY_CONNECTED = 'PATIENT_ALREADY_CONNECTED',
  PATIENT_OPTED_OUT = 'PATIENT_OPTED_OUT',
  PHONE_NUMBER_ALREADY_REGISTERED = 'PHONE_NUMBER_ALREADY_REGISTERED',
  INVITATION_EXISTS = 'INVITATION_EXISTS',
  SMS_SENT = 'SMS_SENT',
}

export type HandleResendInvitationModalWindowsType = {
  isShowPatientAlreadyConnectedModalWindow: boolean;
  isShowPatientAlreadyEnrolledModalWindow: boolean;
  isShowMobilePhoneAlreadyRegisteredModalWindow: boolean;
};

export type RemappedInvitedPatientsType = {
  invitation_id: number;
  name: string;
  phone_number: string;
  status: PatientInvitationStatus;
  is_number_opted_out: boolean;
};

export interface ProviderDirectoryLocation {
  id?: number;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  district?: string;
  country?: string;
  postal_code?: string;
  name?: string;
  external_id?: string;
  external_source?: string;
  organization_name?: string;
  telecoms?: [
    {
      type?: string;
      value?: string;
    },
    {
      type?: string;
      value?: string;
    },
  ];
}

export type MedicalRecordsRequestType = {
  type?: string;
  connection_id?: number;
  relying_party_name?: string;
  client_first_name?: string;
  client_last_name?: string;
  created_at?: string;
  status?: string;
  consumer_uuid?: string;
  ordered_by?: string;
  client_id?: string | number;
  relying_party_id?: string | number;
  id?: string | number;
  provider_directory_location?: ProviderDirectoryLocation;
  document_type?: string;
  request_viewed_by_patient?: boolean;
  recipient?: string;
  patient_comment?: string;
  relying_party_provider_type?: string;
  updated_at?: string;
};

export enum ProviderTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
  EXTERNAL_INDIVIDUAL = 'EXTERNAL_INDIVIDUAL',
  EXTERNAL_ORGANIZATION = 'EXTERNAL_ORGANIZATION',
}
