import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {isRoleMatched} from '../../../shared/utils/user';

export const useShareMedicalRecordsWithProviderPermissions = () => {
  const {roles, type} = useRecoilValue(currentUserState);

  const isNonPhysicianHasRightRolesForRequestRecords =
    isRoleMatched(roles, [
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) && USER_TYPES.NON_PHYSICIAN === type;

  const isPhysicianHasRightRolesForRequestRecords =
    isRoleMatched(roles, [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) && USER_TYPES.PHYSICIAN === type;

  return (
    isNonPhysicianHasRightRolesForRequestRecords ||
    isPhysicianHasRightRolesForRequestRecords
  );
};
