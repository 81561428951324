import {USER_TYPES} from '../../../shared/constants/userTypes';
import {User} from '../../../shared/interfaces/user';
import {RemappedPhysiciansType} from '../constants/types';

export const getDefaultProvider = (
  currentUser: User | null
): RemappedPhysiciansType | null =>
  currentUser?.type === USER_TYPES.PHYSICIAN && currentUser?.name
    ? {label: currentUser.name, id: currentUser.id}
    : null;
