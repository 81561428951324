import React, {FC, InputHTMLAttributes, useCallback, useMemo} from 'react';
import {
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Popper,
  TextField,
} from '@material-ui/core';
import {
  Cancel as CancelIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';

import {RemappedPatientsNamesType} from '../../../main/inbox/constants/types';
import {ANCHOR, colors} from '../../styles/theme';

type Props = {
  placeholderText: string;
  noOptionsFoundText: string;
  data: RemappedPatientsNamesType[];
  loading: boolean;
  error: boolean;
  selectedOptions: RemappedPatientsNamesType[];
  setSelectedOptions: (val: RemappedPatientsNamesType[]) => void;
  testId?: string;
  errorText: string;
  emptyDataText: string;
};

export const MultipleAutocompleteWithCheckBoxes: FC<Props> = ({
  data,
  selectedOptions,
  loading,
  placeholderText,
  noOptionsFoundText,
  errorText,
  emptyDataText,
  error,
  setSelectedOptions,
  testId,
}) => {
  const noOptionsText = useMemo(() => {
    if (error) {
      return <div style={{color: colors.red}}>{errorText}</div>;
    }
    if (data.length === 0 && !error) {
      return emptyDataText;
    }
    return noOptionsFoundText;
  }, [data.length, emptyDataText, error, errorText, noOptionsFoundText]);

  const PopperComponent = useCallback(
    props => (
      <Popper
        modifiers={{
          offset: {enabled: true, offset: `0, ${ANCHOR.style.top}`},
        }}
        {...props}
      />
    ),
    []
  );

  const PaperComponent = useCallback(
    (
      props: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>
    ): JSX.Element => <Card variant="outlined" {...props} />,
    []
  );

  const getOptionLabel = useCallback(
    (option: RemappedPatientsNamesType): string => option.label,
    []
  );

  const getOptionSelected = useCallback(
    (
      option: RemappedPatientsNamesType,
      value: RemappedPatientsNamesType
    ): boolean => option.label === value.label,
    []
  );

  const onChange = useCallback(
    (_, values) => {
      setSelectedOptions(values);
    },
    [setSelectedOptions]
  );

  const renderOption = useCallback(
    (option, {selected}) => (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={selected}
            inputProps={
              {
                'data-testid': testId
                  ? `${testId}-checkbox-${option.label}`
                  : null,
              } as InputHTMLAttributes<HTMLInputElement>
            }
          />
        }
        label={option.label}
      />
    ),
    [testId]
  );

  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        variant="outlined"
        size="small"
        placeholder={placeholderText}
        fullWidth
      />
    ),
    [placeholderText]
  );

  return (
    <Autocomplete
      multiple
      popupIcon={<KeyboardArrowDownIcon />}
      closeIcon={<CancelIcon fontSize="small" />}
      data-testid={testId ? `${testId}-multiple-autocomplete` : null}
      disableClearable
      loadingText={
        <LoadingWrapper>
          <div data-testid={testId ? `${testId}-loading` : null}>
            Loading...
          </div>
          <CircularProgress color="inherit" size={16} />
        </LoadingWrapper>
      }
      options={data}
      loading={loading}
      disableCloseOnSelect
      PaperComponent={PaperComponent}
      PopperComponent={PopperComponent}
      value={selectedOptions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={onChange}
      renderTags={() => null}
      renderOption={renderOption}
      style={{width: '100%'}}
      noOptionsText={noOptionsText}
      renderInput={renderInput}
    />
  );
};

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
