import {includes, isArray} from 'lodash';

import {ROLES} from '../constants/roles';

function checkRoles(prerequisiteRoles) {
  return roles => {
    if (!isArray(roles)) return false;
    return roles.some(role => includes(prerequisiteRoles, role));
  };
}

const relyingPartyRoles = [
  ROLES.RELYING_PARTY_OWNER,
  ROLES.RELYING_PARTY_ADMIN,
  ROLES.RELYING_PARTY_RECORDS_VIEWER,
  ROLES.RELYING_PARTY_RECORDS_REQUESTER,
  ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
  ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
];
export const isUserRelyingParty = checkRoles(relyingPartyRoles);

const relyingPartyAdminOrOwner = [
  ROLES.RELYING_PARTY_OWNER,
  ROLES.RELYING_PARTY_ADMIN,
];
export const isUserRelyingPartyAdminOrOwner = checkRoles(
  relyingPartyAdminOrOwner
);

const userIssuerAdminOrOwner = [ROLES.ISSUER_ADMIN, ROLES.ISSUER_OWNER];
export const isUserIssuerAdminOrOwner = checkRoles(userIssuerAdminOrOwner);

/**
 * find out if there is a role in the current user's roles
 * that matches at least one role in prerequisite roles
 * @param {ROLES[]? | null} currentRoles
 * @param {string[]?} prerequisiteRoles
 */
export function isRoleMatched(currentRoles, prerequisiteRoles) {
  // always matches when there is no prerequisite roles
  if (!prerequisiteRoles) {
    return true;
  }
  if (!Array.isArray(currentRoles) || !Array.isArray(prerequisiteRoles)) {
    return false;
  }
  return currentRoles.some(currentRole =>
    prerequisiteRoles.includes(currentRole)
  );
}

const userIssuer = [
  ROLES.ISSUER_ADMIN,
  ROLES.ISSUER_OWNER,
  ROLES.ISSUER_SUPPORT_AGENT,
];
export const isUserIssuers = checkRoles(userIssuer);
