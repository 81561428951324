import React, {InputHTMLAttributes, useEffect, useState} from 'react';
import {Checkbox, FormControlLabel, Typography} from '@material-ui/core';
import styled from 'styled-components';

import {BaseModalWindow} from '../../../../shared/components/baseModalWindow';
import {Button} from '../../../../shared/components/button';
import {TERMS} from '../../constants/termsAndConditions';

interface ModalWrapperProps {
  isOpen: boolean;
  handleClose: () => void;
}
const TermsAndCondition: React.FC<ModalWrapperProps> = ({
  isOpen,
  handleClose,
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [close, setClose] = useState(false);

  const handleContinue = () => {
    setClose(true);
  };

  const handleModalClose = () => {
    localStorage.setItem('agreedTermsAndConditions', 'false');
    handleClose();
  };

  const handleOnClick = async () => {
    const isChecked = !isCheckboxChecked;
    setIsCheckboxChecked(isChecked);
    localStorage.setItem('agreedTermsAndConditions', isChecked.toString());
  };
  useEffect(() => {
    if (!isCheckboxChecked) {
      setIsContinueDisabled(true);
    } else {
      setIsContinueDisabled(false);
    }
  }, [isCheckboxChecked]);
  return (
    <BaseModalWindow
      open={!close && isOpen}
      onClose={handleModalClose}
      minHeight={460}
      maxWidth={608}
      minWidth={608}
      padding={32}
    >
      <ModalWrapper>
        <HeaderWrapper data-testid="terms-header">
          <Title>{TERMS.title}</Title>
        </HeaderWrapper>
        <ContentWrapper data-testid="terms-content">
          <Paragraph>
            <BoldLine>
              The Care Guide™ is intended for informational purposes only{' '}
            </BoldLine>{' '}
            {TERMS.firstParagraph}
          </Paragraph>
          <Paragraph>{TERMS.secondParagraph}</Paragraph>
          <Paragraph>
            <BoldLine>{TERMS.thirdParagraph}</BoldLine>
          </Paragraph>
        </ContentWrapper>
        <FormControlLabel
          style={{marginBottom: '40px'}}
          control={
            <Checkbox
              inputProps={
                {
                  'data-testid': 'accept-checkbox',
                } as InputHTMLAttributes<HTMLInputElement>
              }
              color="primary"
              onClick={handleOnClick}
            />
          }
          label={TERMS.acceptAgreementCheckbox}
        />
        <ButtonWrapper>
          <Button
            testID="reject-terms"
            className="cancelButton"
            variant="outlined"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            testID="accept-terms"
            onClick={handleContinue}
            disabled={isContinueDisabled}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </BaseModalWindow>
  );
};

export default TermsAndCondition;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Paragraph = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const BoldLine = styled.span`
  font-weight: 600;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 99%;
  max-height: 90vh;
`;

const HeaderWrapper = styled.div`
  width: 100%;
`;
const Title = styled(Typography)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
`;
