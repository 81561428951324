import React from 'react';
import {Link} from '@material-ui/core';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {currentUserState} from '../../../../shared/atoms/authAtom';
import {ROLES} from '../../../../shared/constants/roles';
import {USER_TYPES} from '../../../../shared/constants/userTypes';
import {isRoleMatched} from '../../../../shared/utils/user';
import {selectedPatientState} from '../../atoms/selectedPatientState';
import {CONNECTION_STATUSES} from '../../constants/patientStatuses';
import {QueryKeyOnRevalidateType} from '../../constants/types';
import {useConfirmViewRecords} from '../../hooks/useConfirmViewRecords';
import {useSendRequest} from '../../hooks/useSendRequest';

type Props = {
  patientName: string | null;
  patientUuid: string | null;
  firstName: string;
  invalidateKey: QueryKeyOnRevalidateType;
  connectionStatus?: CONNECTION_STATUSES;
  connectionId?: number;
  relyingPartyId?: number;
  isSharingMedicalRecords?: boolean;
  isUpdateRequested?: boolean;
  clientId?: number;
  isConnectionData: boolean;
};

export const PatientNameLink = ({
  patientName,
  patientUuid,
  connectionStatus,
  connectionId,
  relyingPartyId,
  isSharingMedicalRecords,
  isUpdateRequested,
  firstName,
  invalidateKey,
  clientId,
  isConnectionData,
}: Props): JSX.Element => {
  const isPatientConnected = connectionStatus === CONNECTION_STATUSES.CONNECTED;

  const setSelectedPatient = useSetRecoilState(selectedPatientState);
  const currentUser = useRecoilValue(currentUserState);
  const confirmOpenRecords = useConfirmViewRecords(
    isPatientConnected,
    isSharingMedicalRecords
  );
  const sendRequest = useSendRequest({
    patientName,
    patientUuid,
    firstName,
    invalidateKey,
    relyingPartyId,
    connectionStatus,
    isSharingMedicalRecords,
    isUpdateRequested,
    clientId,
    connectionId,
  });

  const isPhysicianHasRightRolesForViewRecords =
    isRoleMatched(currentUser?.roles, [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) && USER_TYPES.PHYSICIAN === currentUser?.type;

  const isNonPhysicianHasRightRolesForViewRecords =
    isRoleMatched(currentUser?.roles, [
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
    ]) && USER_TYPES.NON_PHYSICIAN === currentUser?.type;

  const isRequestAllowed =
    isConnectionData &&
    (!isPatientConnected || (isPatientConnected && !isSharingMedicalRecords)) &&
    (isPhysicianHasRightRolesForViewRecords ||
      isNonPhysicianHasRightRolesForViewRecords);

  const handleClick = () => {
    if (isRequestAllowed) {
      sendRequest();
    } else {
      confirmOpenRecords(patientUuid, patientName);
    }
    setSelectedPatient({patientName, patientUuid});
  };
  return (
    <Link
      href="/#"
      onClick={e => {
        e.preventDefault();
        handleClick();
      }}
      underline="always"
      data-testid={`${patientUuid}-link-view-medical-record`}
    >
      {patientName}
    </Link>
  );
};
