import React, {FC, useCallback} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button} from '../../../shared/components/button';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {fetchConnectPatient} from '../../../shared/utils/fetchers';
import {CONNECTION_STATUSES} from '../../portalPatients/constants/patientStatuses';
import {notValidTaskModalWindowState} from '../atoms/notValidTaskModalWindowState';
import {CallBackFuncType, ResolutionEnum} from '../constants/types';
import {useCustomizedButtonStyles} from '../hooks/useCustomizedButtonStyles';
import {
  fetchInboxProviderResolutionEvent,
  fetchPatientConnection,
} from '../utils/fetchers';

type Props = {
  handleConnectActionCallBack: CallBackFuncType;
  eventId: string;
  consumerId: string;
  relyingPartyId: number;
  clientId: number;
  patientFirstname: string;
};

export const ConnectPatientButton: FC<Props> = ({
  handleConnectActionCallBack,
  relyingPartyId,
  consumerId,
  eventId,
  clientId,
  patientFirstname,
}) => {
  const classes = useCustomizedButtonStyles();
  const queryClient = useQueryClient();
  const snackbar = useCustomizedSnackbar();
  const currentUser = useRecoilValue(currentUserState);

  const setNotValidTaskModalWindowState = useSetRecoilState(
    notValidTaskModalWindowState
  );

  const handleDismissAction = useCallback(async () => {
    try {
      await fetchInboxProviderResolutionEvent(
        ResolutionEnum.DISMISSED,
        eventId
      );
      await queryClient.invalidateQueries({
        queryKey: ['inbox-providers-events'],
      });
    } catch {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
  }, [eventId, queryClient, snackbar]);

  const handleConnectAction = async () => {
    try {
      const {status} = await fetchPatientConnection(relyingPartyId, consumerId);

      if (
        status === CONNECTION_STATUSES.CONNECTED ||
        status === CONNECTION_STATUSES.PENDING
      ) {
        setNotValidTaskModalWindowState({callBackFunc: handleDismissAction});
      } else {
        await Promise.all([
          fetchConnectPatient({
            relying_party_id: relyingPartyId,
            client_id: currentUser?.id,
            consumer_uuid: consumerId,
            patient_first_name: patientFirstname,
          }),
          fetchInboxProviderResolutionEvent(ResolutionEnum.COMPLETED, eventId),
        ]);
        await queryClient.invalidateQueries({
          queryKey: ['inbox-providers-events'],
        });
      }
    } catch {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
  };

  return (
    <Button
      position="section"
      onClick={() => handleConnectActionCallBack(handleConnectAction)}
      data-testid={`inbox-connect-btn-${eventId}`}
      variant="outlined"
      className={classes.customizedBtn}
    >
      Connect
    </Button>
  );
};
